import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Alert, Label, Input, Badge } from "reactstrap"
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "helpers/pagination_helper";
import Loader from "common/loader";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import { ORDERS_SEARCH } from "helpers/api_endpoints";
import NoImage from "assets/images/no-image.png";
//meta title
document.title = "Orders";
const OrdersList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [orderDate, setOrderDate] = useState('');
    const [addressFromId, setAddressFromId] = useState(0);
    const [addressToId, setAddressToId] = useState('');
    const [shipmentTypeId, setShipmentTypeId] = useState('');
    const [currentStatusId, setCurrentStatusId] = useState('');
    const [shipmentTypeOptions, setShipmentTypeOptions] = useState('');
    const [items, setItems] = useState([]);
    const [services, setServices] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        successMessage();
        post(`${ORDERS_SEARCH}?paginate=${ITEMS_PER_PAGE}&page=${page}`, filters)
            .then(response => {
                const data = response.data;
                setOrders(data.data);
                setTotalPages(data.pagination.totalPages);
                setPage(data.pagination.currentPage);
                setLoading(false);
            })
            .catch(error => {
                console.log(error)
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })
    }, [page, filters]);

    const successMessage = () => {
        if (location.state) {
            setsuccessMsg(location.state.successMsg);
            setTimeout(() => {
                location.state.successMessage = '';
                setsuccessMsg('');
            }, 4000)
        }
    };

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {
            switch (type) {
                case 'name':
                    setName(value);
                    filtvalues.name = value;
                    break;
                default:
                    break;
            }
        }
        setFilters(filtvalues);
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Orders" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                {successMsg ? <Alert style={{ margin: "25px" }} color="success">{successMsg}</Alert> : null}
                                <CardTitle>
                                    <Link to="/orders/add" >
                                        <button style={{ float: "right", marginRight: "20px" }} className="btn btn-sm btn-primary"><i className="dripicons-plus"></i> &nbsp;<Trans>Create</Trans></button>
                                    </Link>
                                </CardTitle>

                                <div style={{ margin: "25px" }}>
                                    <div className="row">
                                        <div className="form-group col-md-3 mt-2">
                                            <Label className="form-label"><Trans>Date</Trans></Label>
                                            <Input
                                                name="orderDate"
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                onChange={(e) => handleFilter('orderDate', e.target.value)}
                                                onKeyDown={(e) => handleFilter('orderDate', e.target.value)}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>


                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>ID</Trans></th>
                                                    <th className="text-center"><Trans>Date</Trans></th>
                                                    <th><Trans>Address From</Trans></th>
                                                    <th><Trans>Address To</Trans></th>
                                                    <th><Trans>User Name</Trans></th>
                                                    <th><Trans>Driver Name</Trans></th>
                                                    <th><Trans>Current Status</Trans></th>
                                                    <th><Trans>Delivery Type</Trans></th>
                                                    <th><Trans>Vehicle Type</Trans></th>
                                                    <th><Trans>Shipment Type</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={7} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    orders.map((order, index) => (
                                                        <tr key={index}>
                                                            <td>{order.id}</td>
                                                            <td>{order.orderDate}</td> 
                                                            <td>{order.addressFrom.title + '' + order.addressFrom.street + '' + order.addressFrom.phone}</td>
                                                            <td>{order.addressTo.title + '' + order.addressTo.street + '' + order.addressTo.phone}</td>
                                                            <td>{order.user.name}</td> 
                                                            <td>{order.driver.name}</td> 
                                                            <td>{order.currentStatus.name}</td> 
                                                            <td>{order.category.name}</td> 
                                                            <td>{order.vehicleCategory.title}</td> 
                                                            <td>{order.shipmentType.title}</td> 
                                                            <td><Link to={`/orders/edit/${order.id}`}><button className="btn btn-sm btn-info"><i className="dripicons-pencil"></i> &nbsp;<Trans>Edit</Trans></button></Link> &nbsp;
                                                                <Link to={`/orders/remove/${order.id}`}><button className="btn btn-sm btn-danger" ><i className="dripicons-trash"></i> &nbsp;<Trans>Remove</Trans> </button></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br></br>
                                    <div className="text-center">
                                        {/* Pagination controls */}
                                        <button className="btn btn-sm btn-dark" onClick={handlePrevPage} disabled={page === 1}>
                                            <Trans>Previous</Trans>
                                        </button>&nbsp;
                                        <button className="btn btn-sm btn-dark" onClick={handleNextPage} disabled={page === totalPages}>
                                            <Trans>Next</Trans>
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}

export default OrdersList
