import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import { SHOW_REASON, UPDATE_REASON, LANGUAGES_SEARCH } from "helpers/api_endpoints";



//meta title
document.title = "Edit Language";

const EditReason = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [valued, setValued] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        post(`${LANGUAGES_SEARCH}`,{status:1})
            .then(response => {
                setLanguages(response.data);
                // console.log(languages);
                setLoading(false);
            })
            .catch(error => {
                navigate('/reasons');
            })
    }, []);
    useEffect(() => {
        get(`${SHOW_REASON}/${params.id}`)
            .then(response => {
                const reason = response.data;
                setid(params.id);
                setValued(reason.valued);
                setValue(reason.value);
                setName(reason.name);
                setStatus(reason.status);
                setLoading(false);
            })
            .catch(error => {
                navigate('/reasons');
            })
    }, [languages]);

    const handleSubmit = () => { 
        put(UPDATE_REASON, { id, name, value, status })
            .then(response => {
                if (response.code == 200) {
                    navigate('/reasons', { state: { successMsg: 'Language Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const setReason = (t,lang,val) =>{
        if(valued[0][lang]==undefined)
            valued[0][lang] = {"title":"","content":""}
        valued[0][lang][t] = val;
        setValue(JSON.stringify(valued)); 
    }
    
    const getValued = (langId) =>{
        if(valued[0]==undefined) return {"title":"","content":""};
        return valued[0][Object.keys(valued[0]).find(el=>el===langId)] || {"title":"","content":""}

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reasons" breadcrumbItem="Edit Reason" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="name"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter Reason Name"
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        required ></Input>
                                                    <Input type="hidden" name="id"></Input>
                                                </div>

                      
                                            {
                                                    languages.map((language, index) => (
                                                        <Row key={index}>
                                                        <div className="form-group col-md-4 mt-2">
                                                            <Label className="form-label"><Trans>Reason Title {language.name}</Trans></Label>
                                                            <Input
                                                                name="reason-title-arabic"
                                                                value={getValued(language.id.toString()).title}
                                                                className="form-control"
                                                                placeholder="Enter Title"
                                                                type="text"
                                                                onChange={(e) => setReason('title',language.id,e.target.value)}
                                                                required ></Input>
                                                        </div>

                                                        <div className="form-group col-md-4 mt-2">
                                                            <Label className="form-label"><Trans>Reason Content {language.name}</Trans></Label>
                                                            <Input
                                                                name="reason-content-arabic"
                                                                value={getValued(language.id.toString()).content}
                                                                className="form-control"
                                                                placeholder="Enter Content"
                                                                type="text"
                                                                onChange={(e) => setReason('content',language.id,e.target.value)}
                                                                required ></Input>
                                                        </div>
                                                        </Row>
                                                    ))
                                            }


                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                                        <option value=''></option>
                                                        <option value={1} selected={status == 1}><Trans>Active</Trans></option>
                                                        <option value={0} selected={status != 1}><Trans>Non Active</Trans></option>
                                                    </select>
                                                </div>
                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditReason
