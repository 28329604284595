import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import { SHOW_LANGUAGE, UPDATE_LANGUAGE } from "helpers/api_endpoints";



//meta title
document.title = "Edit Language";

const EditLanguage = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setname] = useState('');
    const [code, setLanguageCode] = useState('');
    const [symbol, setSymbol] = useState('');
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_LANGUAGE}/${params.id}`)
            .then(response => {
                const language = response.data;
                setid(params.id);
                setLanguageCode(language.code);
                setSymbol(language.symbol);
                setname(language.name);
                setStatus(language.status);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                navigate('/languages');
            })
    }, []);

    const handleSubmit = () => {
        put(UPDATE_LANGUAGE, { id, name, symbol, code, status })
            .then(response => {
                if (response.code == 200) {
                    navigate('/languages', { state: { successMsg: 'Language Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Languages" breadcrumbItem="Edit Language" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="name"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter Language Name"
                                                        type="text"
                                                        onChange={(e) => setname(e.target.value)}
                                                        required ></Input>
                                                    <Input type="hidden" name="id"></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Symbol</Trans></Label>
                                                    <Input
                                                        name="symbol"
                                                        value={symbol}
                                                        className="form-control"
                                                        placeholder="Enter Symbol"
                                                        type="text"
                                                        onChange={(e) => setSymbol(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Language Code</Trans></Label>
                                                    <Input
                                                        name="code"
                                                        value={code}
                                                        className="form-control"
                                                        placeholder="Enter Language Code"
                                                        type="text"
                                                        onChange={(e) => setLanguageCode(e.target.value)} required
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                                        <option value=''></option>
                                                        <option value={1} selected={status == 1}><Trans>Active</Trans></option>
                                                        <option value={0} selected={status != 1}><Trans>Non Active</Trans></option>
                                                    </select>
                                                </div>
                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditLanguage
