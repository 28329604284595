import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert, Table, Container } from "reactstrap";
import { post } from "../../helpers/api_helper";
import UploadImage from "common/uploadImage";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { DRIVERS_SEARCH, REASONS_SEARCH } from "helpers/api_endpoints";
import Select from "react-select";


//meta title
document.title = "Add Driver Document";

const AddDriverDocument = () => {

    const [status, setStatus] = useState();
    const [documentStatus, setDocumentStatus] = useState([{value:"0",label:"Pending"},{value:"1",label:"Accepted"},{value:"2",label:"Rejected"}]);
    const [driver, setDriver] = useState({document:[]});
    const [drivers, setDrivers] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [driversOption, setDriversOption] = useState([]);
    const [errorMsg, seterrorMsg] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => { 
        post(`${DRIVERS_SEARCH}`, {})
            .then(response => {
                const data = response.data;
                setDrivers(data);
                setDriversOption(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })
        post(`${REASONS_SEARCH}`, {refId:"7"})
            .then(response => {
                setReasons(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })


    }, []);

    useEffect(() => { 
        setDocuments(driver.document);    
    }, [driver]);

    const handleSubmit = () => {
        saveUserData();
    }

    const handleToggleCheckBox = (inputFunction) => {
        switch (inputFunction) {
            case 'setStatus':
                if (status == 1) {
                    setStatus(0);
                }
                else {
                    setStatus(1);
                }
                break;
            case 'setCargoEmployee':
                if (cargoEmployee == 1) {
                    setCargoEmployee(0);
                }
                else {
                    setCargoEmployee(1);
                }
                break;
            default:
                break;
        }
    }

    const saveUserData = () => {
        post(ADD_USER, { name: username, email, password, mobile, emirateId, status: 1 })
            .then(response => {
                if (response.code == 201) {
                    const userData = response.data;
                    if (userData.id) {
                        assignDriverRole(userData);
                        saveDriverData(userData);
                    }
                    else {
                        seterrorMsg('Driver Creation Failed');
                    }
                }
            })
            .catch(error => {
                seterrorMsg('Document Creation Failed');
            })
    }

    const saveDriverData = (userData) => {
        post(ADD_DRIVER, { userId: userData.id, name: driverName, licenseNo, licenseExpiry, cargoEmployee, status })
            .then(response => {
                if (response.code == 201) {
                    navigate('/drivers', { state: { successMsg: 'Document Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Document Creation Failed');
            })
    }

    const assignDriverRole = (userData) => {
        post(ASSIGN_ROLE, { UserId: userData.id, RoleId: 3 })
            .then(response => {
            })
            .catch(error => {
                seterrorMsg('Document Creation Failed');
            })
    }

    const handleFilter = (type, value) => {
        if(type == 'driverId')
        {
            // setDriver({document:[]});
            setDriver(drivers.find(driver => {return driver.id==value}));
        }
        else if(type == 'documentStatus')
        {

        }
        else if(type == 'reasons')
        {
            
        }
        return true;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Documents" breadcrumbItem="Add Document" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                            <div className="form-group col-md-2 mt-2">
                                                <Label className="form-label"><Trans>Driver</Trans></Label>
                                                <Select
                                                name="driverId"
                                                value={driversOption.filter(option => {return driver.id==option.value})}
                                                options={driversOption} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('driverId', e.value)}
                                                />
                                            </div>


                                        </Row>
                                        <Card>
                                        <CardBody>
                                       
                                        <Row className="justify-content-center">
                                        {/* <Alert color="secured">{driver.name}</Alert> */}
                                        {
                                            documents.map((document, index) => (
                                                <Col className="mb-2" key={index} sm={12} md={4} lg={4}>
                                                    <Row>
                                                        <div>
                                                            <div className="form-group  mt-2">
                                                                    <img src={document.filePath} width={'100%'}/>
                                                            </div>

                                                        </div>
                                                        <div >
                                                            <div className="form-group mt-2">
                                                                <Label className="form-label"><Trans>{document.name}</Trans></Label>
                                                                <Select
                                                                name="driverId"
                                                                value={document.DriverDocument.status}
                                                                options={documentStatus} 
                                                                className="form-control select2"
                                                                onChange={(e) => handleFilter('documentStatus', e.value)}
                                                                />
                                                                <Select
                                                                name="driverId"
                                                                // value={reasons.filter(option => {return driver.id==option.value})}
                                                                options={reasons} 
                                                                className="form-control select2"
                                                                onChange={(e) => handleFilter('reasons', e.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            )            
                                            )
                                        }
                                        </Row>
                                        
                                        </CardBody>
                                        </Card>
                                        <div className="mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddDriverDocument
