import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form, Alert } from "reactstrap";
import { get, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ITEMS_SEARCH, SERVICES_SEARCH } from "helpers/api_endpoints";
import Select from "react-select";
import PropTypes from 'prop-types'

//meta title
document.title = "Order Detail";

const OrderDet = ({type,item}) => {

    const [details, setDetails] = useState([]);
    const [detailId, setDetailId] = useState(0);
    const [detailName, setDetailName] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [detailsVal, setDetailsVal] = useState([]);

    const [filters, setFilters] = useState([]);
    const [errorMsg, seterrorMsg] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('data',type,item)
        post(type=="item"?ITEMS_SEARCH:SERVICES_SEARCH)
            .then(response => {
                setDetails(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
                setDetailId(type=="item"?item.itemId:item.serviceId);
                setQuantity(item.quantity);
                setPrice(item.price);
                setLoading(false);
            })
            .catch(error => {
                seterrorMsg('Loading Failed '+error);
            })
    }, [loading]);
    useEffect(() => {
        // setDetailId(type=="item"?data.itemId:data.serviceId);
        if(details.length > 0)
            {
                console.log('response',details);
                const tmp = details.filter(option => {return detailId==option.value});
                console.log('filter',detailId,details,tmp);
                setDetailsVal(tmp);
                console.log('detailsVal',detailId,detailsVal,tmp);
            }
    }, [detailId]);
    const handleFilter = (atype, value) => {
        const filtvalues = {};
        if (value) {
            switch (atype) {
                case 'detailId':
                    setDetailId(value);
                    break;
                case 'quantity':
                    setQuantity(value);
                    break;
                case 'price':
                    setPrice(value);
                    break;
                case 'remarks':
                    setRemarks(value);
                    break;
                default:
                    break;
            }
            if(type=='item')
                item.itemId = detailId;
            else
                item.serviceId = detailId;
            item.price = price;
            item.quantity = quantity;
        }
        setFilters(filtvalues);
    }

    return (
        <React.Fragment>

                    <Form
                        className="form-horizontal"
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Select {type=="item"?"Item":"Service"}</Trans></Label>
                                                <Select
                                                name="detailId"
                                                value={detailsVal}
                                                options={details} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('detailId', e.value)}
                                            />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Quantity</Trans></Label>
                                                <Input
                                                name="quantity"
                                                value={quantity}
                                                className="form-control"
                                                placeholder="Enter Quantity "
                                                type="number"
                                                onChange={(e) => handleFilter('quantity', e.target.value)}
                                            ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Price</Trans></Label>
                                                <Input
                                                name="price"
                                                value={price}
                                                className="form-control"
                                                placeholder="Enter Price "
                                                type="text"
                                                onChange={(e) => handleFilter('price', e.target.value)}
                                            ></Input>
                                            </div>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>

        </React.Fragment>
    )
}
OrderDet.propTypes = {
  show: PropTypes.any
}
export default OrderDet
