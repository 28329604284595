import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import UploadImage from "common/uploadImage";
import { SHOW_VEHICLE_CATEGORY, UPDATE_VEHICLE_CATEGORY } from "helpers/api_endpoints";
import FormSectionTitle from "pages/Common/FormSectionTitle";
import SubmitButton from "pages/Common/SubmitButton";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import Description from "./Common/Description";
import UploadImages from "common/uploadImages";

//meta title
document.title = "Edit Vehicle Category";

const EditVehicleCategory = () => {

    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setName] = useState('');
    const [modelIds, setModelIds] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [attributes, setAttributes] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [modelNames, setModelNames] = useState('');
    const [types, setTypes] = useState('');
    const [color, setColor] = useState('');
    const [manufactureYear, setManufactureYear] = useState('');
    const [pilotNos, setPilotNos] = useState('');
    const [passangerNos, setPassangerNos] = useState('');
    const [trunkSizes, setTrunkSizes] = useState('');
    const [height, setHeight] = useState('');
    const [models, setModels] = useState('');
    const [gcms, setGcms] = useState('');
    const [trailingUnits, setTrailingUnits] = useState('');
    const [registrationSources, setRegistrationSources] = useState('');
    const [registrationExpiry, setRegistrationExpiry] = useState('');
    const [capacity, setCapacity] = useState('');
    const [image, setImage] = useState('');
    const [images, setImages] = useState('');
    const [symbol, setSymbol] = useState('');
    const [status, setStatus] = useState(0);
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_VEHICLE_CATEGORY}/${params.id}`)
            .then(response => {
                const vehicle_category = response.data;
                setSymbol(vehicle_category.symbol);
                setid(vehicle_category.id);
                setName(vehicle_category.name);
                setTitle(vehicle_category.title);
                setSubTitle(vehicle_category.subTitle);
                setAttributes(JSON.stringify(vehicle_category.attributes));
                setModelIds(vehicle_category.modelIds);
                setModelNames(vehicle_category.modelNames);
                setType(vehicle_category.type);
                setTypes(vehicle_category.types);
                setHeight(vehicle_category.height);
                setModels(vehicle_category.models);
                setCapacity(vehicle_category.capacity);
                setManufactureYear(vehicle_category.manufactureYear);
                setColor(vehicle_category.color);
                setPilotNos(vehicle_category.pilotNos);
                setStatus(vehicle_category.status);
                setImage(vehicle_category.image);
                setImages(vehicle_category.images);
                setDescription(JSON.stringify(vehicle_category.description));
                setTrailingUnits(vehicle_category.trailingUnits);
                setTrunkSizes(vehicle_category.trunkSizes);
                setRegistrationExpiry(vehicle_category.registrationExpiry);
                setRegistrationSources(vehicle_category.registrationSources);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                // navigate('/vehicle-categories');
            })
    }, []);
    
    const handleSubmit = () => {
        const imagePath = document.getElementById('imagePath').value;
        const config = {
            id,
            name,
            title,
            subTitle,
            modelNames,
            modelIds,
            manufactureYear,
            type,
            types,
            color,
            pilotNos,
            passangerNos,
            trunkSizes,
            gcms,
            symbol,
            trailingUnits,
            attributes,
            description,
            height,
            models,
            registrationExpiry,
            registrationSources,
            capacity,
            status,
            image: imagePath
        };
        console.log('config',config)
        put(UPDATE_VEHICLE_CATEGORY, config)
            .then(response => {
                if (response.code == 200) {
                    navigate('/vehicle-categories', { state: { successMsg: 'Vehicle Category Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status == 1) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Vehicle Categories" breadcrumbItem="Edit Vehicle Category" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        {loading ? <Loader></Loader> :
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <FormSectionTitle state={{ title: 'General Information' }}></FormSectionTitle>
                                            <Row>
                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="name"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter  Name"
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Title</Trans></Label>
                                                    <Input
                                                        name="title"
                                                        value={title}
                                                        className="form-control"
                                                        placeholder="Enter Title"
                                                        type="text"
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Sub Title</Trans></Label>
                                                    <Input
                                                        name="subTitle"
                                                        value={subTitle}
                                                        className="form-control"
                                                        placeholder="Enter Sub Title"
                                                        type="text"
                                                        onChange={(e) => setSubTitle(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Model Names</Trans></Label>
                                                    <Input
                                                        name="modelNames"
                                                        value={modelNames}
                                                        className="form-control"
                                                        placeholder="Enter Model Names"
                                                        type="text"
                                                        onChange={(e) => setModelNames(e.target.value)}
                                                    ></Input>
                                                </div>


                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Type</Trans></Label>
                                                    <Input
                                                        name="type"
                                                        value={type}
                                                        className="form-control"
                                                        placeholder="Enter Type"
                                                        type="text"
                                                        onChange={(e) => setType(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Types</Trans></Label>
                                                    <Input
                                                        name="types"
                                                        value={types}
                                                        className="form-control"
                                                        placeholder="Enter Types"
                                                        type="text"
                                                        onChange={(e) => setTypes(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Height</Trans></Label>
                                                    <Input
                                                        name="height"
                                                        value={height}
                                                        className="form-control"
                                                        placeholder="Enter Height"
                                                        type="text"
                                                        onChange={(e) => setHeight(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Models</Trans></Label>
                                                    <Input
                                                        name="models"
                                                        value={models}
                                                        className="form-control"
                                                        placeholder="Enter Models"
                                                        type="text"
                                                        onChange={(e) => setModels(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Capacity</Trans></Label>
                                                    <Input
                                                        name="capacity"
                                                        value={capacity}
                                                        className="form-control"
                                                        placeholder="Enter Capacity"
                                                        type="text"
                                                        onChange={(e) => setCapacity(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Manufacture Year</Trans></Label>
                                                    <Input
                                                        name="manufactureYear"
                                                        value={manufactureYear}
                                                        className="form-control"
                                                        placeholder="Enter Manufacture Year"
                                                        type="text"
                                                        onChange={(e) => setManufactureYear(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Color</Trans></Label>
                                                    <Input
                                                        name="color"
                                                        value={color}
                                                        className="form-control"
                                                        placeholder="Enter Color"
                                                        type="text"
                                                        onChange={(e) => setColor(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Gcm</Trans></Label>
                                                    <Input
                                                        name="gcms"
                                                        value={gcms}
                                                        className="form-control"
                                                        placeholder="Enter Gcm"
                                                        type="text"
                                                        onChange={(e) => setGcms(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Model Names</Trans></Label>
                                                    <Input
                                                        name="modelNames"
                                                        value={modelNames}
                                                        className="form-control"
                                                        placeholder="Enter Model Names"
                                                        type="text"
                                                        onChange={(e) => setModelNames(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Pilot Nos</Trans></Label>
                                                    <Input
                                                        name="pilotNos"
                                                        value={pilotNos}
                                                        className="form-control"
                                                        placeholder="Enter Pilot Nos"
                                                        type="text"
                                                        onChange={(e) => setPilotNos(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Registration Expiry</Trans></Label>
                                                    <Input
                                                        name="registrationExpiry"
                                                        value={registrationExpiry}
                                                        className="form-control"
                                                        placeholder="Enter Registration Expiry"
                                                        type="text"
                                                        onChange={(e) => setRegistrationExpiry(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Registration Sources</Trans></Label>
                                                    <Input
                                                        name="registrationSources"
                                                        value={registrationSources}
                                                        className="form-control"
                                                        placeholder="Enter Registration Sources"
                                                        type="text"
                                                        onChange={(e) => setRegistrationSources(e.target.value)}
                                                    ></Input>
                                                </div>


                                                {/* <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Description</Trans></Label>
                                                    <Input
                                                        name="description"
                                                        value={description}
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        type="text"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    ></Input>
                                                </div> */}

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Trunk Sizes</Trans></Label>
                                                    <Input
                                                        name="trunkSizes"
                                                        value={trunkSizes}
                                                        className="form-control"
                                                        placeholder="Enter Trunk Sizes"
                                                        type="text"
                                                        onChange={(e) => setTrunkSizes(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Trailing Units</Trans></Label>
                                                    <Input
                                                        name="trailingUnits"
                                                        value={trailingUnits}
                                                        className="form-control"
                                                        placeholder="Enter Trailing Units"
                                                        type="text"
                                                        onChange={(e) => setTrailingUnits(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-2 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={1}
                                                            id="defaultCheck2"
                                                            defaultChecked={status == 1}
                                                            onClick={handleToggleCheckBoxStatus}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            <Trans>Active</Trans>
                                                        </label>
                                                    </div>
                                                </div>
                                            </Row>
                                            <br></br>
                                            <Description state={{ description: description }}></Description>
                                            <br></br>
                                            <FormSectionTitle state={{ title: 'Media Section' }}></FormSectionTitle>
                                            <Row>
                                                <UploadImage state={{ InputLabel: 'Main Image', filePath: image, type: 'vehicleCategories', InputWidth: '4' }}></UploadImage>
                                                <UploadImages state={{ InputLabel: 'Images', files: images, type: 'vehicleCategories', InputWidth: '8' }}></UploadImages>
                                            </Row>
                                            <div className="mt-2">
                                                <SubmitButton state={{ text: "Submit" }}></SubmitButton>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditVehicleCategory
