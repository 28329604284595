//ROLES
export const ROLES = "roles/list";
export const ADD_ROLE = "roles/add";
export const ASSIGN_ROLE = "roles/role-user";
export const UPDATE_ROLE = "roles/update";
export const REMOVE_ROLE = "roles/remove";

//SCREENS
export const SCREENS_LIST = "screens/list";
export const ADD_SCREEN = "screens/add";
export const UPDATE_SCREEN = "screens/update";
export const REMOVE_SCREEN = "screens/remove";

//SCREEN SECTIONS
export const SCREEN_SECTION_LIST = "screen-sections/list";
export const ADD_SCREEN_SECTION = "screen-sections/add";
export const UPDATE_SCREEN_SECTION = "screen-sections/update";
export const REMOVE_SCREEN_SECTION = "screen-sections/remove";

//COUNTRIES
export const COUNTRIES_SEARCH = "countries/search";
export const SHOW_COUNTRY = "countries/show";
export const ADD_COUNTRY = "countries/add";
export const UPDATE_COUNTRY = "countries/update";
export const REMOVE_COUNTRY = "countries/remove";

//LANGUAGES
export const LANGUAGES_SEARCH = "languages/search";
export const SHOW_LANGUAGE = "languages/show";
export const ADD_LANGUAGE = "languages/add";
export const UPDATE_LANGUAGE = "languages/update";
export const REMOVE_LANGUAGE = "languages/remove";

//Reasons
export const REASONS_SEARCH = "text/search";
export const SHOW_REASON = "text/show";
export const ADD_REASON = "text/add";
export const UPDATE_REASON = "text/update";
export const REMOVE_REASON = "text/remove";

//CATEGORIES
export const CATEGORIES_SEARCH = "categories/search";
export const SHOW_CATEGORY = "categories/show";
export const ADD_CATEGORY = "categories/add";
export const UPDATE_CATEGORY = "categories/update";
export const REMOVE_CATEGORY = "categories/remove";

//BANNERS
export const BANNERS_SEARCH = "banners/search";
export const SHOW_BANNER = "banners/show";
export const ADD_BANNER = "banners/add";
export const UPDATE_BANNER = "banners/update";
export const REMOVE_BANNER = "banners/remove";

//OFFERS
export const OFFERS_SEARCH = "offers/search";
export const SHOW_OFFER = "offers/show";
export const ADD_OFFER = "offers/add";
export const UPDATE_OFFER = "offers/update";
export const REMOVE_OFFER = "offers/remove";

//TICKETS
export const TICKETS_SEARCH = "tickets/search";
export const SHOW_TICKET = "tickets/show";

//GENERAL
export const GENERAL_STARTUP = "general/startup";
export const UPDATE_STARTUP = "applications/update";

//TEXT
export const ADD_TEXT = "text/add";

//TERMS
export const TERMS_LIST = "general/terms";
export const UPDATE_TERM = "general/terms-update";

//FAQS
export const FAQ_LIST = "general/faq";
export const UPDATE_FAQ = "general/faq-update";


//USERS
export const USERS_SEARCH = "users/search";
export const USERS_LIST = "users/list";
export const SHOW_USER = "users/show";
export const ADD_USER = "users/add";
export const UPDATE_USER = "users/update";
export const REMOVE_USER = "users/remove";

//ADDRESSES
export const ADDRESS_SEARCH = "addresses/search";
export const ADDRESS_TYPE = "addresses/filter";
export const SHOW_ADDRESS = "addresses/show";
export const ADD_ADDRESS = "addresses/add";
export const UPDATE_ADDRESS = "addresses/update";
export const REMOVE_ADDRESS = "addresses/remove";

//VEHICLES
export const VEHICLES_SEARCH = "vehicles/search";
export const SHOW_VEHICLE = "vehicles/show";
export const ADD_VEHICLE = "vehicles/add";
export const UPDATE_VEHICLE = "vehicles/update";
export const REMOVE_VEHICLE = "vehicles/remove";

//VEHICLE CATEGORIES
export const VEHICLE_CATEGORY_SEARCH = "vehicle-category/search";
export const SHOW_VEHICLE_CATEGORY = "vehicle-category/show";
export const ADD_VEHICLE_CATEGORY = "vehicle-category/add";
export const UPDATE_VEHICLE_CATEGORY = "vehicle-category/update";
export const REMOVE_VEHICLE_CATEGORY = "vehicle-category/remove";

//SHIPMENT TYPES
export const SHIPMENT_TYPES_SEARCH = "shipment-type/search";
export const SHOW_SHIPMENT_TYPE = "shipment-type/show";
export const ADD_SHIPMENT_TYPE = "shipment-type/add";
export const UPDATE_SHIPMENT_TYPE = "shipment-type/update";
export const REMOVE_SHIPMENT_TYPE = "shipment-type/remove";

export const STATUS_SEARCH = "status/search";
export const SHOW_STATUS = "status/show";
export const ADD_STATUS = "status/add";
export const UPDATE_STATUS = "status/update";
export const REMOVE_STATUS = "status/remove";

//DRIVERS
export const DRIVERS_SEARCH = "drivers/search";
export const ADD_DRIVER = "drivers/add";
export const ADD_DOCUMENT_FILE = "documents/add-file";
export const ADD_DRIVER_DOCUMENT = "documents/add-file";
export const ADD_DRIVER_FULL = "drivers/add-driver";
export const UPDATE_DRIVER = "drivers/update";
export const REMOVE_DRIVER = "drivers/remove";

//ITEMS
export const ITEMS_SEARCH = "items/search";
export const SHOW_ITEM = "items/show";
export const ADD_ITEM = "items/add";
export const UPDATE_ITEM = "items/update";
export const REMOVE_ITEM = "items/remove";

//SERVICES
export const SERVICES_SEARCH = "services/search";
export const SHOW_SERVICE = "services/show";
export const ADD_SERVICE = "services/add";
export const UPDATE_SERVICE = "services/update";
export const REMOVE_SERVICE = "services/remove";

//ORDERS
export const ORDERS_SEARCH = "orders/search";
export const SHOW_ORDER = "orders/show";
export const ADD_ORDER = "orders/add";
export const UPDATE_ORDER = "orders/update";
export const REMOVE_ORDER = "orders/remove";

