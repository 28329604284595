import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form, Alert } from "reactstrap";
import { get, post, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import UploadImage from "common/uploadImage";
import { ADD_ORDER, ADDRESS_TYPE, CATEGORIES_SEARCH, COUNTRIES_SEARCH, DRIVERS_SEARCH, ITEMS_SEARCH, SERVICES_SEARCH, SHIPMENT_TYPES_SEARCH, VEHICLE_CATEGORY_SEARCH, STATUS_SEARCH, USERS_SEARCH, UPDATE_ORDER, SHOW_ORDER } from "helpers/api_endpoints";
import Select from "react-select";
import OrderDet from "./OrderDet";


//meta title
document.title = "Add Order";

const AddOrder = () => {


    const params = useParams();
    const [id, setId] = useState('');

    const [currentStatusId, setCurrentStatusId] = useState(0);
    const [currentStatusName, setCurrentStatusName] = useState(0);
    const [userId, setUserId] = useState(0);
    const [userName, setUserName] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [countryId, setCountryId] = useState(181);
    const [countryName, setCountryName] = useState('');
    const [driverId, setDriverId] = useState(0);
    const [driverName, setDriverName] = useState('');
    const [vehicleCategoryId, setVehicleCategoryId] = useState(0);
    const [vehicleCategoryName, setVehicleCategoryName] = useState('');
    const [addressFromId, setAddressFromId] = useState(0);
    const [addressFromName, setAddressFromName] = useState('');
    const [addressToId, setAddressToId] = useState(0);
    const [addressToName, setAddressToName] = useState('');
    const [shipmentTypeId, setShipmentTypeId] = useState(0);
    const [shipmentTypeName, setShipmentTypeName] = useState('');
    const [shipmentTypeOptions, setshipmentTypeOptions] = useState([]);
    const [orderDate, setOrderDate] = useState('2024-01-01');
    const [items, setItems] = useState([{itemId:0,quantity:0,price:0,item:{}}]);
    const [services, setServices] = useState([{serviceId:0,quantity:0,price:0,service:{}}]);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [vehicleCategories, setVehicleCategories] = useState([]);
    const [addressesFrom, setAddressesFrom] = useState([]);
    const [addressesTo, setAddressesTo] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [statues, setStatues] = useState([]);
    const [filters, setFilters] = useState([]);
    const [errorMsg, seterrorMsg] = useState('');
    const [update, setUpdate] = useState(0);
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    const loadData = () => {
        if(params.id != undefined) {
            get(`${SHOW_ORDER}/${params.id}`)
                .then(response => {
                    const order = response.data;
                    setId(params.id);
                    setUserId(order.userId);
                    setUserName(order.user.name);
                    setCategoryId(order.categoryId);
                    setCategoryName(order.category.name);
                    setCountryId(order.countryId);
                    setCountryName(order.country.name);
                    setCurrentStatusId(order.currentStatusId);
                    setCurrentStatusName(order.currentStatus.name);
                    setDriverId(order.driverId);
                    setDriverName(order.driver.name);
                    setVehicleCategoryId(order.vehicleCategoryId);
                    setVehicleCategoryName(order.vehicleCategory.name);
                    setAddressFromId(order.addressFromId);
                    setAddressFromName(order.addressFrom.title);
                    setAddressToId(order.addressToId);
                    setAddressToName(order.addressTo.title);
                    setShipmentTypeId(order.shipmentTypeId);
                    setShipmentTypeName(order.shipmentType.name);
                    setshipmentTypeOptions(order.shipmentTypeOptions);
                    setOrderDate(order.orderDate);
                    setItems(order.orderItems);
                    setServices(order.orderServices);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    navigate('/orders');
                })
        }
    }

    useEffect(() => {
        loadData();
    }, [loading]);

    useEffect(() => {
        post(ADDRESS_TYPE+'/0')
            .then(response => {
                const temp = response.data.map(el=>{
                    return {value:el.id,label:el.title}
                });
                setAddressesFrom(temp);
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(ADDRESS_TYPE+'/1')
            .then(response => {
                const temp = response.data.map(el=>{
                    return {value:el.id,label:el.title}
                });
                setAddressesTo(temp);
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(CATEGORIES_SEARCH)
            .then(response => {
                setCategories(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(DRIVERS_SEARCH)
            .then(response => {
                setDrivers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(DRIVERS_SEARCH)
            .then(response => {
                setDrivers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(COUNTRIES_SEARCH)
            .then(response => {
                setCountries(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(USERS_SEARCH)
            .then(response => {
                setUsers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(VEHICLE_CATEGORY_SEARCH)
            .then(response => {
                setVehicleCategories(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(SHIPMENT_TYPES_SEARCH)
            .then(response => {
                setShipmentTypes(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(STATUS_SEARCH)
            .then(response => {
                setStatues(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
    }, []);

    useEffect(() => {
        console.log('items',items)
    }, [items]);

    const handleSubmit = () => {
        if(id != '')
        {
            put(UPDATE_ORDER, { id, categoryId, vehicleCategoryId, userId, currentStatusId, addressFromId, addressToId, shipmentTypeId, shipmentTypeOptions, orderDate, items, services, countryId, driverId })
            .then(response => {
                if (response.code == 201) {
                    navigate('/orders', { state: { successMsg: 'Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Creation Failed');
            })
        }
        else
        {
            post(ADD_ORDER, { categoryId, vehicleCategoryId, userId, currentStatusId, addressFromId, addressToId, shipmentTypeId, shipmentTypeOptions, orderDate, items, services, countryId, driverId })
            .then(response => {
                if (response.code == 201) {
                    navigate('/orders', { state: { successMsg: 'Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Creation Failed');
            })
        }
    }

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {console.log('value',value);
            switch (type) {
                case 'category':
                    setCategoryId(value);
                    break;
                case 'vehicleCategory':
                    setVehicleCategoryId(value);
                    break;
                case 'addressfrom':
                    setAddressFromId(value);
                    break;
                case 'addressTo':
                    setAddressToId(value);
                    break;
                case 'driverId':
                    setDriverId(value);
                    break;
                case 'countryId':
                    setCountryId(value);
                    break;
                case 'shipmentType':
                    setShipmentTypeId(value);
                    break;
                case 'currentStatusId':
                    setCurrentStatusId(value);
                    break;
                case 'orderDate':
                    setOrderDate(value);
                    break;
                case 'user':
                    setUserId(value);
                    break;
                default:
                    break;
            }
        }
        // setFilters(filtvalues);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Items" breadcrumbItem={params.id==undefined?"Add Order":"Edit Order"} />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Delivery Type</Trans></Label>
                                                <Select
                                                name="categoryId"
                                                value={categories.filter(option => {return categoryId==option.value})}
                                                options={categories} 
                                                className="form-control "
                                                onChange={(e) => handleFilter('category', e.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Vehicle Type</Trans></Label>
                                                <Select
                                                name="vehicleCategoryId"
                                                value={vehicleCategories.filter(option => {return vehicleCategoryId==option.value})}
                                                options={vehicleCategories} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('vehicleCategory', e.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Customer</Trans></Label>
                                                <Select
                                                name="userId"
                                                options={users} 
                                                value={users.filter(option => {return userId==option.value})}
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('user', e.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Date</Trans></Label>
                                                <Input
                                                name="orderDate"
                                                value={orderDate}
                                                className="form-control"
                                                placeholder="Enter Date "
                                                type="date"
                                                onChange={(e) => handleFilter('orderDate', e.target.value)}
                                                ></Input>
                                            </div>


                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Address From</Trans></Label>
                                                <Select
                                                name="addressFromId"
                                                value={addressesFrom.filter(option => {return addressFromId==option.value})}
                                                options={addressesFrom} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('addressfrom', e.value)}
                                                />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Address To</Trans></Label>
                                                <Select
                                                name="addressToId"
                                                value={addressesTo.filter(option => {return addressToId==option.value})}
                                                options={addressesTo} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('addressTo', e.value)}
                                                />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Driver</Trans></Label>
                                                <Select
                                                name="driverId"
                                                value={drivers.filter(option => {return driverId==option.value})}
                                                options={drivers} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('driverId', e.value)}
                                                />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Shipment Type</Trans></Label>
                                                <Select
                                                name="shipmentTypeId"
                                                value={shipmentTypes.filter(option => {return shipmentTypeId==option.value})}
                                                options={shipmentTypes} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('shipmentType', e.value)}
                                                />
                                            </div>


                                            <div className="form-group col-md-2 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <Select
                                                name="currentStatusId"
                                                value={statues.filter(option => {return currentStatusId==option.value})}
                                                options={statues} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('currentStatusId', e.value)}
                                                />
                                            </div>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                {items.map((item,index) => (
                                    <div data-test={item.itemId} key={index}>
                                        <OrderDet type="item" item={item} />
                                    </div>

                                ))}
                                <div className="mt-4">
                                    <Button type="button" color="info" onClick={ e => {e.preventDefault()
                                                                                items.push({itemId:0,quantity:0,price:0,item:{}})
                                                                                setUpdate(Math.random())}}>
                                        <Trans>Add</Trans>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {services.map((service,index) => (
                                    <div key={index}>
                                        <OrderDet type="service" item={service} />
                                    </div>

                                ))}
                                <div className="mt-4">
                                    <Button type="button" color="info" onClick={ e => {e.preventDefault()
                                                                                services.push({serviceId:0,quantity:0,price:0,service:{}})
                                                                                setUpdate(Math.random())}}>
                                        <Trans>Add</Trans>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddOrder
