import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ADD_REASON, LANGUAGES_SEARCH } from "helpers/api_endpoints";


//meta title
document.title = "Add Reason";

const AddReason = () => {

    const [name, setName] = useState('Rejection Reason');
    const [value, setValue] = useState('');
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(true);
    const [languages, setLanguages] = useState([]);
    const [valued, setValued] = useState({});
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        post(`${LANGUAGES_SEARCH}`,{status:1})
            .then(response => {
                setLanguages(response.data);
                setLoading(false);
            })
            .catch(error => {
                navigate('/reasons');
            })
    }, []);

    const handleSubmit = () => {
        post(ADD_REASON, { name, value, refType:"Reason", refId:"7", status })
            .then(response => {
                if (response.code == 201) {
                    navigate('/reasons', { state: { successMsg: 'Reason Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Reason Creation Failed');
            })
    }

    const setReason = (t,lang,val) =>{
        if(valued[lang]==undefined)
            valued[lang] = {"title":"","content":""}
        valued[lang][t] = val;
        setValue(JSON.stringify([valued])); 
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reasons" breadcrumbItem="Add Reason" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Name</Trans></Label>
                                                <Input
                                                    name="name"
                                                    value={name}
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    type="text"
                                                    onChange={(e) => setName(e.target.value)}
                                                    required ></Input>
                                            </div>
                                            {
                                                    languages.map((language, index) => (
                                                        <Row key={index}>
                                                        <div className="form-group col-md-4 mt-2">
                                                            <Label className="form-label"><Trans>Reason Title {language.name}</Trans></Label>
                                                            <Input
                                                                name="reason-title-arabic"
                                                                // value={name}
                                                                className="form-control"
                                                                placeholder="Enter Title"
                                                                type="text"
                                                                onChange={(e) => setReason('title',language.id,e.target.value)}
                                                                required ></Input>
                                                        </div>

                                                        <div className="form-group col-md-4 mt-2">
                                                            <Label className="form-label"><Trans>Reason Content {language.name}</Trans></Label>
                                                            <Input
                                                                name="reason-content-arabic"
                                                                // value={name}
                                                                className="form-control"
                                                                placeholder="Enter Content"
                                                                type="text"
                                                                onChange={(e) => setReason('content',language.id,e.target.value)}
                                                                required ></Input>
                                                        </div>
                                                        </Row>
                                                    ))
                                            }


                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                                    <option value=''></option>
                                                    <option value={1} selected={status == 1}><Trans>Active</Trans></option>
                                                    <option value={0} selected={status == 0}><Trans>Non Active</Trans></option>
                                                </select>
                                            </div>

                                        </Row>
                                        <div className="text-center  mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddReason
